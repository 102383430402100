import { useRef } from 'react';
import { LongPressEventType, useLongPress } from 'use-long-press';
import { differenceInSeconds } from 'date-fns';
import { IconActivityHeartbeat, IconKeyboard, IconVolume } from '@tabler/icons-react';
import { cn } from '@/lib/utils';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useChatInput, useChatMessages, useChatSession } from '@/hooks/useChatStore';
import { useVoiceRecorder } from '@/hooks/useVoiceRecorder';
import { toast } from 'sonner';
import { motion } from 'framer-motion';
import VoiceLoader from '@/assets/icons/voice-generating.svg';

export function VoiceInput() {
  const currentTimeRef = useRef<Date | null>(null);
  const isMobile = useIsMobile();
  const { setInputType } = useChatInput();
  const durationRef = useRef<number>(0);
  const { currentSession } = useChatSession();
  const { isWaitingForResponse, sendVoice } = useChatMessages(currentSession);

  const { start, stop, isRecording, error } = useVoiceRecorder((data) => {
    sendVoice(data, durationRef.current);
  });

  if (error) {
    toast('请允许麦克风权限');
  }
  const bind = useLongPress(
    isWaitingForResponse
      ? null
      : () => {
          currentTimeRef.current = new Date();
          start();
        },
    {
      onFinish: () => {
        const startTime = currentTimeRef.current;
        durationRef.current = startTime ? differenceInSeconds(new Date(), startTime) : 0;
        stop();
      },
      cancelOnMovement: 200,
      threshold: 200,
      detect: isMobile ? LongPressEventType.Touch : LongPressEventType.Mouse,
    }
  );

  return (
    <>
      <motion.div
        layout
        className={cn(
          'bg-chat-dock relative flex shrink-0 select-none flex-col items-center rounded-t-2xl pb-8',
          isRecording && 'z-20 overflow-hidden rounded-t-full'
        )}
      >
        <form className="w-full">
          <div className="flex w-full items-center justify-between gap-4 px-4 pt-3">
            <button
              className="shrink-0"
              type="button"
              onClick={async () => {
                setInputType('text');
              }}
            >
              {!isRecording && <IconKeyboard className="text-foreground/60" />}
            </button>
            <div className="flex flex-grow flex-col items-center gap-2">
              <button
                type="button"
                className={cn(
                  'flex w-full flex-col items-center justify-center gap-4 rounded-md bg-white py-2',
                  isRecording && 'bg-transparent'
                )}
                {...bind()}
                disabled={isWaitingForResponse}
              >
                {isWaitingForResponse ? (
                  <span className="flex gap-4 opacity-60">
                    <IconActivityHeartbeat className="animate-pulse" />
                    <span>心音正在努力聆听理解...</span>
                  </span>
                ) : (
                  <span>{isRecording ? '松开 发送' : '按住 说话'}</span>
                )}
                {isRecording && <IconVolume className="text-foreground/60" />}
              </button>
            </div>
          </div>
        </form>
      </motion.div>
      {isRecording && (
        <div className="absolute inset-0 z-10 flex size-full select-none items-center justify-center bg-black/60">
          <div className="relative w-fit rounded-md bg-opacity-75 bg-gradient-to-t from-[#FFC9B0] to-[#FBC6F1]/80 px-8 py-1">
            <VoiceLoader className="w-16 opacity-80" />
            <div className="absolute bottom-0 left-1/2 size-2 -translate-x-1/2 translate-y-1 rotate-45 bg-[#FBC6F1]" />
          </div>
        </div>
      )}
    </>
  );
}
