import { useEffect, useState } from 'react';

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsMobile(
      /android|iphone|ipad|ipod|blackberry|bb10|playbook|windows phone|webos|opera mini|iemobile|wpdesktop/i.test(
        userAgent
      )
    );
  }, []);

  return isMobile;
}