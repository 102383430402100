import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import Ouhang from '@/assets/profiles/ouhang.png';
import DongMeng from '@/assets/profiles/dongmeng.png';
import ZhaoRui from '@/assets/profiles/zhaorui.png';
import PanQin from '@/assets/profiles/panqin.png';
import WangQing from '@/assets/profiles/wangqing.png';
import TianHui from '@/assets/profiles/tianhui.png';
import YunXi from '@/assets/profiles/yunxi.png';

type TeamCarouselItemCardProps = {
  name: string;
  role: string;
  image: string;
  description?: string;
};

function TeamCarouselItemCard({ name, role, image, description }: TeamCarouselItemCardProps) {
  return (
    <CarouselItem className="group relative h-96 rounded-md bg-white md:basis-1/3 lg:basis-1/4 xl:basis-1/6">
      <div className="relative flex size-full flex-col items-center justify-center">
        <div className="flex flex-col items-center gap-24">
          <img src={image} alt="team" className="h-24 w-24 rounded-full object-cover" />
          <div className="flex flex-col items-center gap-4">
            <h4 className="text-lg font-bold">{name}</h4>
            <p className="text-foreground/60 text-sm">{role}</p>
          </div>
        </div>
      </div>
      {description && (
        <div className="absolute inset-0 size-full translate-y-full bg-gradient-to-b from-white to-amber-500/60 transition-transform group-hover:translate-y-0">
          <p className="z-10 px-4 py-6 text-center font-bold text-slate-700">{description}</p>
        </div>
      )}
    </CarouselItem>
  );
}

export function TeamCarousel() {
  const team = [
    {
      name: '欧航',
      role: '算法工程师',
      image: Ouhang,
      description:
        '普惠心理服务，让更多人得到帮助是我们的使命。AI人工智能的颠覆性创新让我们的愿景成为可能。这条路或许荆棘丛生，但我们从不孤单。',
    },
    {
      name: '董萌',
      role: '算法工程师',
      image: DongMeng,
      description: '对于AI在心理学的可能性充满好奇和希望.',
    },
    {
      name: '赵睿',
      role: '算法工程师',
      image: ZhaoRui,
      description: '作为一名AI算法工程师，希望将技术应用于让人类世界变得更美好。很荣幸和大家一起，关注情绪与心灵，让大模型变得更有温度。',
    },
    {
      name: '潘覃',
      role: '算法工程师',
      image: PanQin,
    },
    {
      name: '王清',
      role: '算法工程师',
      image: WangQing,
    },
    {
      name: '何天慧',
      role: '产品经理',
      image: TianHui,
      description: 'We come,we see,we conquer.拥抱变化，让有AI的未来的变得更温暖，更美好。',
    },
    {
      name: '黄韵茜',
      role: '设计师',
      image: YunXi,
      description: '保持开放，保持好奇，保有恒心。我希望用设计搭建起桥梁，去探索科技与人心的深度链接，助力心灵的修复与成长。',
    },
  ];
  return (
    <Carousel
      opts={{
        align: 'start',
      }}
      className="w-full py-12"
    >
      <CarouselContent className="gap-4 px-12">
        {team.map((member) => (
          <TeamCarouselItemCard key={member.name} {...member} />
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-4" />
      <CarouselNext className="right-4" />
    </Carousel>
  );
}
