import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { Toaster } from '@/components/ui/sonner';
import { useAuth } from '@/hooks/useAuth';
import { ChatWindow } from '@/components/ChatWindow';

export const Route = createFileRoute('/chat')({
  component: Chat,
});

function Chat() {
  const { user } = useAuth();
  const nav = useNavigate();

  if (!user) {
    nav({
      to: '/login',
    });
    return null;
  }

  return (
    <>
      <ChatWindow />
      <Toaster richColors position="top-center" />
    </>
  );
}
