import { Screen } from '@/components/Screen';
import { createFileRoute, Link, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { LayoutGroup, motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import BannerBackground from '@/assets/images/banner-background.webp';
import Background2 from '@/assets/images/landing-bg-2.webp';
import Logo from '@/assets/logo/logo-full.svg';
import SVG1 from '@/assets/LandingPage1.svg';
import LandingSection1 from '@/assets/images/landing-section-1.png';
import LandingSection2 from '@/assets/images/landing-section-2.png';
import LandingSection3 from '@/assets/images/landing-section-3.png';
import LandingSection4 from '@/assets/images/landing-section-4.png';
import Contact from '@/assets/images/contact.jpg';

import { ChatMessageList } from '@/components/chat/chat-message-list';
import { ChatBubble, ChatBubbleMessage } from '@/components/chat/chat-bubble';
import { LandingPageSideBar } from '@/components/LandingPageSideBar';
import { Card, CardContent } from '@/components/ui/card';
import { TeamCarousel } from '@/components/TeamCarousel';

export const Route = createFileRoute('/')({
  component: LandingPage,
});

export enum ScreenId {
  Home = 'Home',
  Product = 'Product',
  Benchmark = 'Benchmark',
  Academic = 'Academic',
  About = 'About',
  Cooperation = 'Cooperation',
}

export const screens = new Map<ScreenId, string>([
  [ScreenId.Home, '首页'],
  [ScreenId.Product, '产品介绍'],
  [ScreenId.Benchmark, '基准测试'],
  [ScreenId.Academic, '学术支持'],
  [ScreenId.About, '关于我们'],
  [ScreenId.Cooperation, '合作与建议'],
]);

function LandingPage() {
  const [activeScreen, setActiveScreen] = useState<ScreenId>(ScreenId.Home);
  const messages = ['欢迎来到心音SoulEcho,你今天的心情怎么样呢', '我今天不是很开心', '很抱歉听到这句话， 你愿意详细聊聊么？'];
  const navigate = useNavigate();
  return (
    <div className="scrollbar-hidden">
      <div className="scrollbar-hidden fixed inset-x-0 top-0 z-20 hidden items-center justify-between bg-white px-12 py-3 lg:flex">
        <button
          className="shrink-0 cursor-pointer"
          onClick={() => {
            navigate({
              to: '/',
            });
          }}
        >
          <Logo className="h-8 w-auto" />
        </button>
        <div className="flex items-center gap-12">
          <LayoutGroup>
            <div className="flex h-full items-center gap-8">
              {[...screens.keys()].map((screenId) => (
                <a
                  key={screenId}
                  className="relative flex flex-col items-center"
                  href={`#${screenId}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${screenId}`)?.scrollIntoView({ behavior: 'instant' });
                    setActiveScreen(screenId);
                  }}
                >
                  <span>{screens.get(screenId)}</span>
                  {screenId === activeScreen && (
                    <motion.div layoutId="selected" className={'absolute bottom-0 h-[2px] w-full bg-rose-500'} />
                  )}
                </a>
              ))}
            </div>
          </LayoutGroup>
          <Link to="/login" className="bg-primary text-primary-foreground rounded-md px-4 py-2 hover:bg-[#FFA23E]">
            登录 / 注册
          </Link>
        </div>
      </div>

      <LandingPageSideBar setActiveScreen={setActiveScreen} />

      <Screen id={ScreenId.Home} whileInView={() => setActiveScreen(ScreenId.Home)}>
        <div className="relative flex size-full flex-col items-center py-12 md:py-24">
          <img src={BannerBackground} alt="" className="absolute inset-0 h-full w-full object-cover" />
          <div className="z-10 flex w-4/5 flex-col items-center pt-10">
            <div className="arrow-bottom relative mb-8 rounded-full border border-white bg-gradient-to-r from-[#FF9FEC] to-[#FFB898] px-4 py-2 text-white shadow-[#F5C4D0]">
              <div className="absolute bottom-0 left-1/2 size-3 -translate-x-1/2 translate-y-1/2 rotate-45 border-b border-r border-white bg-gradient-to-r from-[#FF9FEC] to-[#FFB898]"></div>
              让内心的声音被听见，与自己和解
            </div>
            <div className="pb-12 text-3xl md:text-5xl">专注年轻人心理健康的全方位守护者</div>
            <div className="pb-16 text-center md:text-lg">
              心音SoulEcho是一款致力于提升年轻人心理健康的智能陪伴平台。我们结合了先进的人工智能技术与科学的心理疗法，旨在为年轻人提供个性化、随时可及的心理支持与情感疏导。通过综合的服务，我们帮助年轻人在面对情绪困扰、学业压力或社交问题时，找到专业的引导和温暖的陪伴。
            </div>
            <Link to="/chat">
              <Button className="px-8 py-6 hover:bg-[#FFA23E]">免费试用</Button>
            </Link>
          </div>
        </div>
      </Screen>
      <Screen id={ScreenId.Product} whileInView={() => setActiveScreen(ScreenId.Product)}>
        <div className="flex w-full flex-col items-center">
          <div className="relative flex w-full flex-col items-center justify-around p-12 md:flex-row">
            <div className="flex flex-1 flex-col items-center">
              <div className="flex items-center gap-2 text-3xl font-black md:text-5xl">
                <span>心音</span>
                <div className="relative">
                  <span>SoulEcho</span>
                  <div className="absolute inset-x-0 bottom-0 -z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
                </div>
              </div>
              <div className="w-60 pt-8 font-light">
                心音SoulEcho的目标不仅仅是提供情感支持，更是帮助年轻人掌握自我心理健康管理的能力，让他们在未来的人生中，能够自信、坚韧地应对各种挑战。
              </div>

              <Link to="/chat">
                <Button className="my-12 w-fit px-4 py-2">去聊聊吧 {`>_`}</Button>
              </Link>
            </div>
            <div className="relative h-[400px] w-[600px] flex-1 rounded-xl bg-gradient-to-tl from-[#FDC6F2] to-[#FED7C4]">
              <div className="absolute inset-2 flex items-center justify-center rounded-md bg-white/80 backdrop-blur-sm">
                <ChatMessageList className="flex w-full flex-col gap-2 px-4">
                  <div className="flex h-full w-full flex-col items-center gap-2 py-12">
                    {messages.map((message, index) => (
                      <ChatBubble key={index} variant={index % 2 === 0 ? 'received' : 'sent'}>
                        <ChatBubbleMessage variant={index % 2 === 0 ? 'received' : 'sent'}>{message}</ChatBubbleMessage>
                      </ChatBubble>
                    ))}
                  </div>
                </ChatMessageList>
              </div>
            </div>
          </div>

          <div className="relative mx-12 flex flex-col p-8 xl:mx-48">
            <img src={Background2} alt="" className="absolute inset-0 -z-10 size-full rounded-2xl object-cover" />
            <div className="flex w-full flex-col items-center justify-between md:flex-row">
              <div className="flex flex-col gap-8 font-light">
                <div className="font-bold">基于AI的个性化聊愈</div>
                <div>
                  每个人的情感需求与心理状况都是独特的，因此我们利用先进的人工智能技术，打造了一种可根据用户情绪、心理状态实时调整的个性化聊愈体验。心音SoulEcho的AI助手通过对用户行为模式的分析，能够为用户提供个性化的支持，随时调整对话内容，以确保每次互动都能满足用户的心理需求。无论是情感疏导还是帮助缓解焦虑情绪，我们的AI助手都能够精确提供适合的支持。
                </div>
              </div>
              <SVG1 className="h-60 w-60 shrink-0" />
            </div>
          </div>

          <div className="mx:4 relative my-12 grid grid-cols-1 md:mx-16 md:grid-cols-2">
            <div className="flex flex-col gap-6 p-8">
              <img src={LandingSection1} alt="" className="size-16 rounded-xl" />
              <div className="text-lg font-bold">任何时间，任何地点地陪伴</div>
              <div className="h-1 w-6 rounded-md bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
              <div className="text-lg font-thin">
                心音SoulEcho以随时随地的陪伴为宗旨，无论用户身处何处，都能通过智能设备与我们进行情感交流。无论是放学后感到焦虑的时刻，还是深夜陷入孤独与无助，我们的智能心理陪伴平台都能第一时间提供支持与安慰，让每位年轻人感受到不离不弃的陪伴。
              </div>
            </div>

            <div className="flex flex-col gap-6 p-8">
              <img src={LandingSection2} alt="" className="size-16 rounded-xl" />
              <div className="text-lg font-bold">基于认知行为CBT疗法</div>
              <div className="h-1 w-6 rounded-md bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
              <div className="text-lg font-thin">
                我们采用了国际公认的认知行为疗法（CBT），帮助年轻人通过系统化的心理干预来改善思维模式与情绪状态。CBT
                通过帮助用户识别和改变负面思维与行为，能够有效应对压力、焦虑、抑郁等常见心理问题。心音SoulEcho通过AI引导的互动对话，将这种疗法融入日常生活，帮助年轻人掌握应对情绪波动的工具和方法。
              </div>
            </div>

            <div className="flex flex-col gap-6 p-8">
              <img src={LandingSection3} alt="" className="size-16 rounded-xl" />
              <div className="text-lg font-bold">教育和普及心理健康知识</div>
              <div className="h-1 w-6 rounded-md bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>

              <div className="text-lg font-thin">
                心音SoulEcho不仅是一个心理健康支持工具，也是一个重要的心理健康知识教育平台。我们定期推出有关心理健康的科普内容，帮助年轻人和他们的家长更好地理解情绪管理、自我关爱、压力应对等方面的知识。通过教育和普及，心音SoulEcho希望消除心理健康话题的误解和社会偏见，让更多的年轻人能够认识到心理健康的重要性，并主动寻求帮助和支持。{' '}
              </div>
            </div>

            <div className="flex flex-col gap-6 p-8">
              <img src={LandingSection4} alt="" className="size-16 rounded-xl" />
              <div className="text-lg font-bold">注重安全隐私</div>
              <div className="h-1 w-6 rounded-md bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>

              <div className="text-lg font-thin">
                年轻人的隐私与数据安全是心音SoulEcho的首要任务。我们严格遵循国际数据保护标准，确保用户的个人信息、心理记录和对话内容在全程中受到加密保护。平台设计了安全可靠的心理辅导环境，确保年轻人能够放心表达内心情感，不必担心隐私泄露。心音SoulEcho致力于为每位用户提供一个安心的心理支持空间。{' '}
              </div>
            </div>

            <div className="height-[1px] absolute top-1/2 hidden w-full border border-neutral-100 md:block"></div>
            <div className="width-[1px] absolute left-1/2 hidden h-full border border-neutral-100 md:block"></div>
          </div>
        </div>
      </Screen>
      <Screen id={ScreenId.Benchmark} whileInView={() => setActiveScreen(ScreenId.Benchmark)}>
        <div className="flex w-full flex-col items-center bg-[#F6F2E9] py-12">
          <div className="flex items-center text-3xl font-black">
            <div className="relative z-10">
              <span>基准测试</span>
              <div className="absolute inset-x-0 bottom-0 -z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
            </div>
          </div>
        </div>
      </Screen>
      <Screen id={ScreenId.Academic} whileInView={() => setActiveScreen(ScreenId.Academic)}>
        <div className="flex w-full flex-col items-center py-12">
          <div className="flex items-center text-3xl font-black">
            <div className="relative z-10">
              <span>学术支持</span>
              <div className="absolute inset-x-0 bottom-0 -z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
            </div>
          </div>
        </div>
      </Screen>
      <Screen id={ScreenId.About} whileInView={() => setActiveScreen(ScreenId.About)}>
        <div className="flex w-full flex-col items-center bg-[#F6F2E9] py-12">
          <div className="flex items-center text-3xl font-black">
            <div className="relative z-10">
              <span>关于我们</span>
              <div className="absolute inset-x-0 bottom-0 -z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
            </div>
          </div>
          <TeamCarousel />
        </div>
      </Screen>
      <Screen id={ScreenId.Cooperation} whileInView={() => setActiveScreen(ScreenId.Cooperation)}>
        <div className="my-8 flex w-full flex-col items-center px-6 py-12 md:px-12">
          <div className="flex items-center text-3xl font-black">
            <div className="relative z-10">
              <span>合作与建议</span>
              <div className="absolute inset-x-0 bottom-0 -z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
            </div>
          </div>
          <Card className="my-8 w-full rounded-md">
            <CardContent className="flex flex-col items-center gap-6 md:flex-row">
              <img src={Contact} alt="contact" className="w-48 object-cover" />
              <div className="flex flex-1 flex-col items-center gap-2">
                <div className="flex gap-2">
                  <span className="font-bold"> 电话:</span>
                  <span className="text-muted-foreground">15867113912 </span>
                </div>
                <div className="flex gap-2">
                  <span className="font-bold"> 微信:</span>
                  <span className="text-muted-foreground">Kresnik59 </span>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </Screen>

      <div className="w-full bg-black py-12"></div>
    </div>
  );
}
