import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { isMobilePhone } from 'validator';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormMessage } from './ui/form';
import { trpc } from '@/utils/trpc';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from '@tanstack/react-router';

const formSchema = z.object({
  phone: z.string().refine((input) => isMobilePhone(input), { message: '请输入正确的手机号' }),
  code: z.string().length(6, {
    message: '请输入正确的验证码',
  }),
});

export function LoginForm() {
  const { mutate: sendCode } = trpc.auth.loginInBySMS.useMutation();
  const { mutateAsync: verifyCode } = trpc.auth.verifyCode.useMutation();
  const [sent, setSent] = useState<boolean>(false);
  const nav = useNavigate();
  const [timeUntilNextSent, setTimeUntilNextSent] = useState<number>(60);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: '',
      code: '',
    },
    criteriaMode: 'all',
  });
  const { signInUser } = useAuth();
  useEffect(() => {
    if (sent) {
      const interval = setInterval(() => {
        setTimeUntilNextSent((prev) => Math.max(0, prev - 1));
        if (timeUntilNextSent === 0) {
          setSent(false);
          setTimeUntilNextSent(60);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [sent, timeUntilNextSent]);
  return (
    <Card className="w-full max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">登录</CardTitle>
        <CardDescription>手机号快捷登录</CardDescription>
      </CardHeader>
      <form
        onSubmit={form.handleSubmit(async (values: z.infer<typeof formSchema>) => {
          try {
            const { token, user } = await verifyCode(values);
            signInUser(user.id, token);
            nav({
              to: '/chat',
            });
          } catch (error) {
            form.setError('code', { message: '验证码错误' });
          }
        })}
      >
        <CardContent className="grid gap-4">
          <Form {...form}>
            <div className="grid gap-2">
              <FormField
                name="phone"
                control={form.control}
                render={({ field }) => (
                  <div className="flex flex-col gap-2">
                    <Input {...field} id="phone" placeholder="请输入手机号" />
                    <FormMessage className="px-2" />
                  </div>
                )}
              />
            </div>
            <div className="grid gap-2">
              <FormField
                name="code"
                control={form.control}
                render={({ field }) => (
                  <div className="flex flex-col gap-2">
                    <div className="relative">
                      <Input {...field} id="password" placeholder="请输入验证码" className="pr-4" />
                      <button
                        type="button"
                        disabled={sent}
                        className={cn(
                          'absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer text-xs underline',
                          sent && 'text-gray-400'
                        )}
                        onClick={async () => {
                          if (await form.trigger('phone')) {
                            sendCode({ phone: form.getValues('phone') });
                            setSent(true);
                          }
                        }}
                      >
                        {sent ? `重新获取(${timeUntilNextSent})` : '获取验证码'}
                      </button>
                    </div>
                    <FormMessage className="px-2" />
                  </div>
                )}
              />
            </div>
          </Form>
        </CardContent>
        <CardFooter>
          <Button className="w-full" type="submit">
            登录
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
}
