import { cn } from '@/lib/utils';
import React, { type HTMLAttributes } from 'react';
import { ScrollArea } from '../ui/scroll-area';

interface ChatMessageListProps extends HTMLAttributes<HTMLDivElement> {}

const ChatMessageList = React.forwardRef<HTMLDivElement, ChatMessageListProps>(({ children, className, ...props }, ref) => (
  // @ts-expect-error dir is conflicting with HTMLAttributes
  <ScrollArea className={cn('flex h-full w-full flex-col gap-3', className)} ref={ref} {...props}>
    {children}
  </ScrollArea>
));

ChatMessageList.displayName = 'ChatMessageList';

export { ChatMessageList };
