import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { unstable_httpSubscriptionLink, splitLink, httpLink, loggerLink } from '@trpc/client';
import { useState } from 'react';
import superjson from 'superjson';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { trpc } from '@/utils/trpc';

// @ts-expect-error EventSource is not defined in the global scope
window.EventSource = EventSourcePolyfill;

export function TrpcWrapper({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition: (op) => op.type === 'subscription',
          true: unstable_httpSubscriptionLink({
            url: '/api',
            transformer: superjson,
            EventSource: EventSourcePolyfill,
            //  @ts-expect-error EventSourceOptions is ill-type
            eventSourceOptions: async () => ({
              headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }),
          }),
          false: httpLink({
            url: '/api',
            transformer: superjson,
            headers: () => ({
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }),
          }),
        }),
        loggerLink({
          enabled: (opts) =>
            (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
