import { useCallback } from 'react';
import { create, useStore } from 'zustand';

type Store = {
  user: {
    id: number;
  } | null;
  token: string | null;
  setUser: (user: { id: number }) => void;
  setToken: (token: string) => void;
};

const AuthStore = create<Store>((set) => ({
  user: localStorage.getItem('userId') ? { id: parseInt(localStorage.getItem('userId')!) } : null,
  setUser: (user) => set({ user }),
  token: localStorage.getItem('token'),
  setToken: (token) => set({ token }),
}));

export function useAuth() {
  const { setUser, setToken, user } = useStore(AuthStore);
  const signInUser = useCallback((userId: number, token: string) => {
    setUser({
      id: userId,
    });
    setToken(token);
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId.toString());
  }, [setToken, setUser]);
  return {
    user,
    signInUser,
  };
}
