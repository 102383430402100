import { useEffect, useRef, type PropsWithChildren } from 'react';
import { useInView } from 'framer-motion';

interface Props extends PropsWithChildren {
  id: string;
  whileInView: () => void;
}
export function Screen({ id, children, whileInView }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, {
    margin: '-20% 0px -20% 0px',
    amount: 0.5,
  });
  useEffect(() => {
    if (isInView) {
      // TODO: handle this while we have enough content for each section, otherwise the scrolling is not action well
      whileInView();
    }
  }, [id, isInView, whileInView]);
  return (
    <div ref={ref} className="relative flex w-full flex-col" id={id}>
      {children || id}
    </div>
  );
}
