import { IconMenu2 } from '@tabler/icons-react';
import { Drawer, DrawerContent, DrawerTrigger } from './ui/drawer';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { screens, type ScreenId } from '@/routes';

type Props = {
  setActiveScreen: React.Dispatch<React.SetStateAction<ScreenId>>;
};

export function LandingPageSideBar({ setActiveScreen }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="sticky top-0 z-50 flex w-auto justify-between bg-white px-6 py-2 lg:hidden">
        <Drawer direction="left" open={isOpen} onOpenChange={setIsOpen} disablePreventScroll>
          <DrawerTrigger onClick={() => setIsOpen(true)}>
            <IconMenu2 />
          </DrawerTrigger>
          <DrawerContent className="inset-y-0 mt-0 w-48 py-4">
            <div className="divide flex w-full flex-col items-center gap-10">
              {[...screens.keys()].map((screen) => (
                <a
                  key={screen}
                  href={`#${screen}`}
                  className="underline decoration-[#FDC6F2] underline-offset-8"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${screen}`)?.scrollIntoView({ behavior: 'instant' });
                    setActiveScreen(screen);
                    setIsOpen(false);
                  }}
                >
                  {screen}
                </a>
              ))}
            </div>
          </DrawerContent>
        </Drawer>
        <Link to="/login" className="bg-primary text-primary-foreground rounded-md px-4 py-2">
          登录
        </Link>
      </div>
    </>
  );
}
