import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { LoginForm } from '@/components/LoginForm';
import { useAuth } from '@/hooks/useAuth';
import { useEffect } from 'react';

export const Route = createFileRoute('/login')({
  component: Index,
});

function Index() {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate({
        to: '/chat',
      });
    }
  }, [navigate, user]);

  return (
    <div className="flex h-screen w-screen items-center justify-center overflow-hidden">
      <LoginForm />
    </div>
  );
}
