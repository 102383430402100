import { useReducer } from 'react';
import { MediaRecorder, register} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';


(async () => {
  await register(await connect());
})();

type ReturnedSig = {
  recorder: MediaRecorder | null;
  start: () => Promise<void>;
  stop: () => void;
  isRecording: boolean;
  error: any;
};

type State = {
  isRecording: boolean;
  recorder: MediaRecorder | null;
  data: Blob | null;
  error: Error | null;
};

type Actions =
  | { type: 'start' }
  | { type: 'startRecording'; payload: { recorder: MediaRecorder } }
  | { type: 'stop' }
  | { type: 'hasError'; payload: { error: any } };

const initState: State = {
  isRecording: false,
  recorder: null,
  data: null,
  error: null,
};

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'start':
      return { ...state, isRecording: true };
    case 'stop':
      return { ...state, isRecording: false };
    case 'startRecording':
      return { ...state, isRecording: true, recorder: action.payload.recorder };
    case 'hasError':
      return { ...state, isRecording: false, error: action.payload.error };
    default:
      return state;
  }
};

export const useVoiceRecorder = (cb: (result: Blob) => void): ReturnedSig => {
  const [state, dispatch] = useReducer(reducer, initState);

  const finishRecording = ({ data }: { data: Blob }) => {
    cb(data);
  };

  const start = async () => {
    try {
      if (state.isRecording) return;
      dispatch({ type: 'start' });
      const stream = await navigator.mediaDevices.getUserMedia({ audio: {
        channelCount: 1, 
        sampleSize: 16, 
        sampleRate: 16000
      } });

      const recorder = new MediaRecorder(stream, {
        mimeType: 'audio/wav',
      });

      // @ts-ignore
      dispatch({ type: 'startRecording', payload: { recorder } });
      recorder.start();
      recorder.addEventListener('dataavailable', finishRecording);
      if (state.error) dispatch({ type: 'hasError', payload: { error: null } });
    } catch (err) {
      console.error(err);
      dispatch({ type: 'hasError', payload: { error: err } });
    }
  };

  const stop = () => {
    try {
      const recorder = state.recorder;
      dispatch({ type: 'stop' });
      if (recorder) {
        if (recorder.state !== 'inactive') recorder.stop();
        recorder.removeEventListener('dataavailable', finishRecording);
      }
    } catch (err) {
      dispatch({ type: 'hasError', payload: { error: err } });
    }
  };

  return {
    start,
    stop,
    recorder: state.recorder,
    isRecording: state.isRecording,
    error: state.error,
  };
};
